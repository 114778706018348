import React from "react"
import StandardLayout from "../layouts/standard"
import PageCover from "../components/page-cover"
import MasonryGrid from "../components/masonry-grid"

export default function Gallery(props) {
  return (
    <StandardLayout title="Gallery">
      <div>
        <PageCover />
      </div>
      <section className="uk-section">
        <div className="uk-container">
          <MasonryGrid />
        </div>
      </section>
    </StandardLayout>
  )
}
