import React, { useEffect, useState } from 'react';
import fascia1 from '../images/gallery/Fascia-1.jpg';
import cuboid1 from '../images/gallery/Cuboid-1.jpg';

export default function MasonryGrid() {
    const { galleryImages } = require('../data.json');
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        let categories = [];
        galleryImages.forEach(image => {
            if (!categories.find(cat => cat === image.type)) categories.push(image.type);
        });
        setCategories(categories);
    }, [])

    return (
        <div data-uk-filter="target: .js-filter">

            <ul class="uk-subnav uk-subnav-pill">
                <li data-uk-filter-control=".all" class="uk-active"><a href="#">All</a></li>
                {categories.map(cat => <li data-uk-filter-control={`.${cat}`}><a href="#">{cat}</a></li>)}
            </ul>

            <div data-uk-lightbox className="mv6">
                <ul class="js-filter uk-child-width-1-2 uk-child-width-1-3@m uk-text-center" data-uk-grid="masonry: true">
                    {galleryImages.map(media => <li className={`all ${media.type}`}>
                        <div classNames="uk-card">
                            <a href={media.url} className="standard-link">
                                <img src={media.url} />
                                {/* <div className="uk-overlay uk-text-bottom uk-transition-slide-bottom">
                                    <div>
                                        <h3>{media.title}</h3>
                                        <p>{media.subtitle}</p>
                                    </div>
                                </div> */}
                            </a>
                        </div>
                    </li>)}
                </ul>
            </div>

        </div>
    )
}